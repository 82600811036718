import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { CgClose } from 'react-icons/cg';
import { HiArrowCircleRight } from 'react-icons/hi';
import { useWindowSize } from 'usehooks-ts';

import useProfile from '@/modules/core/hooks/useProfileHook';
import { UserType } from '@/modules/profile/types';
import Button, { ButtonIconPosition } from '@/tayara-kit/Button';
import Divider, { Orientation } from '@/tayara-kit/Divider';
import Text from '@/tayara-kit/Text';

const LoginFinish = () => {
  const { userData } = useProfile();
  const [showPopup, setShowPopup] = useState(false);
  const windowSize = useWindowSize();
  const isMediumScreen = windowSize.width > 430;

  useEffect(() => {
    const hasPopupBeenShownInHomePage = localStorage.getItem(
      'hasPopupBeenShowninHomePage'
    );

    // Check if the popup has been shown on the home page
    if (hasPopupBeenShownInHomePage !== 'true') {
      const hasPopupBeenShownInLoginPage = localStorage.getItem(
        'hasPopupBeenShowninLoginPage'
      );

      // Show the popup only if it hasn't been shown on either page
      if (hasPopupBeenShownInLoginPage !== 'true') {
        setTimeout(() => {
          setShowPopup(true);
          localStorage.setItem('hasPopupBeenShowninLoginPage', 'true');
        }, 100);
      }
    }
  }, []);

  function handleCloseOnboarding() {
    setShowPopup(false);
  }

  return (
    <div className="max-w-screen w-screen lg:w-[99999px] lg:max-w-xl bg-base-100 lg:border border-gray-200 p-6 lg:p-8 lg:rounded-lg relative lg:h-17">
      <div className="flex justify-center">
        <Image
          width={176}
          height={157}
          src="/media/t-celebrate.svg"
          alt="tayara celebration illustration"
          className="w-40"
        />
      </div>
      <div className="flex flex-col items-center justify-center mt-10 gap-y-2">
        <Text text={`Bienvenue ${userData?.fullname}!`} as="h2" />
        <Divider orientation={Orientation.HORIZONTAL} />
        <Link href={'/profile'} replace passHref className="w-full">
          <Button
            aria-label="Profil"
            icon={<HiArrowCircleRight size={20} />}
            iconPosition={ButtonIconPosition.End}
            className="w-full"
          >
            Voir mon profil
          </Button>
        </Link>
        <Link href={'/post-listing'} replace passHref className="w-full">
          <Button
            aria-label="Créer annonce"
            icon={<HiArrowCircleRight size={20} />}
            iconPosition={ButtonIconPosition.End}
            className="w-full"
          >
            Créer une annonce
          </Button>
        </Link>
        <Link
          href={'/profile/?t=myListings'}
          replace
          passHref
          className="w-full"
        >
          <Button
            aria-label="Acceuil"
            icon={<HiArrowCircleRight size={20} />}
            iconPosition={ButtonIconPosition.End}
            className="w-full"
          >
            Voir toutes les annonces
          </Button>
        </Link>
      </div>
      {showPopup && userData.userType === UserType.PRO && (
        <div
          className="fixed z-[9999] top-0 left-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-sm justify-center items-center"
          onClick={(e) => {
            if (e.target !== e.currentTarget) return;
            handleCloseOnboarding();
          }}
        >
          <div className=" w-fit relative mx-auto py-4 lg:py-2 top-[20.5vh] sm:top-[19%] lg:top-[18%] bg-white border border-primary shadow-slate-400 rounded-xl m-5 lg:p-5 shadow-lg">
            <button
              className="absolute top-3 lg:top-6 right-3 lg:right-6 p-2 border border-gray-400 hover:border-gray-600 hover:bg-gray-100 rounded-md h-auto "
              onClick={() => handleCloseOnboarding()}
            >
              <CgClose size={10} />
            </button>

            <img
              src="/media/dashboardIntroDesktop.png"
              alt="tayara dashboard interface on a desktop device"
              className=" items-center justify-center w-fit transition-all duration-300 hidden lg:block rounded-2xl"
            />

            <button
              className="hidden lg:block absolute top-3 lg:top-[80%] right-3 lg:right-[29%] italic p-2 text-sm font-medium underline text-primary h-auto "
              onClick={() => handleCloseOnboarding()}
            >
              <Link href={'/profile/?t=dashboard'} passHref>
                Voir mon tableau de bord
              </Link>
            </button>

            <img
              src="/media/dashboardIntroMobile.png"
              alt="tayara dashboard interface on a mobile device"
              className={`items-center justify-center w-[90vw] ${
                isMediumScreen && '!w-[24rem] h-[30rem]'
              } transition-all duration-300 block lg:hidden rounded-2xl`}
            />

            <button
              className="block lg:hidden absolute top-[90%] sm:top-[90%] right-[31.5%] sm:right-[30%] italic p-2 text-2xs sm:text-sm font-medium underline text-primary h-auto "
              onClick={() => handleCloseOnboarding()}
            >
              <Link href={'/profile/?t=dashboard'} passHref>
                Voir mon tableau de bord
              </Link>{' '}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginFinish;
